import React from 'react';

const Rocket = ({  }) => (
    <g style={{transform: "translate(-28.5px, -50px)", transformOrigin: "center" }}>
        <path d="M42.2868 72.2944H14.9011C8.45739 59.3074 12.8875 8.65801 28.1912 0C41.0786 7.79221 49.536 57.1429 42.2868 72.2944Z" fill="white"/>
        <ellipse cx="27.7885" cy="30.7359" rx="7.65189" ry="8.22511" fill="#2C3E50"/>
        <path d="M46.7169 48.4849C47.9251 60.6061 46.3141 67.0996 44.3005 73.1602C46.8511 73.8817 52.8384 75.9307 56.3824 78.355C59.2821 66.9264 51.2811 53.824 46.7169 48.4849Z" fill="white"/>
        <path d="M10.2832 48.4849C9.075 60.6061 10.6859 67.0996 12.6996 73.1602C10.1489 73.8817 4.16168 75.9307 0.617644 78.355C-2.28202 66.9264 5.7189 53.824 10.2832 48.4849Z" fill="white"/>
        <g className="rocket__trail-container">
            <path className="rocket__trail" d="M20.5393 80.5195C22.1502 77.4026 26.983 77.0563 28.9966 77.0563C31.0103 77.0563 35.0376 77.6335 36.6485 80.5195C38.2595 83.4055 30.2048 94.8052 28.9966 100C27.3857 94.8052 18.9284 83.6364 20.5393 80.5195Z" fill="white" stroke="white"/>
        </g>
    </g>
);

export default Rocket;